import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { SEO, EventHeader, EventFooter, EventSponsor, Speakers, Terms } from 'components';
import { graphql, navigate } from 'gatsby';
import { LocalContext, FirebaseContext } from 'context';

const eventRegistrationTemplate = ({ data }) => {
  const { setSelectedEvent, theme } = useContext(LocalContext);
  const { user } = useContext(FirebaseContext);
  const { frontmatter: event } = data.markdownRemark;
  useEffect(() => {
    setSelectedEvent({
      name: event.name.replace('<br />', ' '),
      title: event.title,
      eid: event.eid,
      colors: event.colors,
      slug: event.slug
    });
    return () => {
      setSelectedEvent(null);
    };
  }, [event.eid]);

  useEffect(() => {
    if (user?.eventsUserCanAccess.includes(event.eid) || event.status === 'past') {
      navigate(`/events/${event.slug}/livestream`);
    }
  }, [user, event.status, event.eid]);

  return (
    <>
      <SEO
        pageSpecificTitle={event.title.replace('<br />', ' ')}
        pageSpecificDescription={event.description}
        pageSpecificThumbnailAlt={event.title.replace('<br />', ' ')}
      />
      <EventHeader id="header" event={event} />
      <EventSponsor eventSponsor={event.sponsor} />
      {event.speakers?.length && (
        <SpeakersContainer $theme={theme}>
          <Speakers colors={event.colors} speakers={event.speakers} />
          {/* {event.eid === '11' && (
            <p
              style={{
                textAlign: 'center',
                width: '100%',
                fontSize: '1.25rem',
                fontWeight: '700',
                padding: '3rem 1.25rem',
                color: '#A58A4B'
              }}>
              Further speakers to be announced
            </p>
          )} */}
        </SpeakersContainer>
      )}
      <Terms />
      <EventFooter id="footer" />
    </>
  );
};

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        name
        slug
        status
        eid
        colors {
          primary
          secondary
          tertiary
        }
        title
        subtitle
        banner {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        sponsor {
          name
          video
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, height: 216, placeholder: BLURRED)
            }
          }
          description
        }
        speakers {
          id
          name
          position
          fbLink
          twLink
          linkedinLink
          emailLink
          shortSummary
          summary
          image {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        seoThumbnail {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
        bannerVideo
        staticBannerImage {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, height: 900, quality: 90)
          }
        }
        description
        date
        start
        end
        location
        startTime
        endTime
      }
    }
  }
`;

const Container = styled.div`
  color: var(--black);
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  height: auto;
  margin: 0 auto;
  max-width: 1440px;
  padding: 5rem 0.625rem 2.5rem;
  position: relative;
  width: 100%;
  @media (min-width: 768px) and (min-height: 1000px) and (max-width: 1150px) {
    padding: 6rem 0 12.5rem;
  }
  @media (min-width: 1150px) {
    padding: ${({ eid }) => (eid === '9' ? '18.5rem 0 5rem' : '10.5rem 0 5rem')};
    height: auto;
    margin-bottom: 0;
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Section = styled.section`
  grid-column: 1/7;
  h2 {
    color: #00004e;
    font-size: 1.5rem;
    margin-bottom: 1em;
    text-align: center;
    width: 100%;
  }
  p {
    color: #3c3c3c;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5em;
    margin: 0 auto;
    margin-bottom: 3rem;
    max-width: 100%;
    text-align: center;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
    p {
      margin-bottom: 4rem;
    }
  }
  @media (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const SpeakersContainer = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 3rem 0rem;
  position: relative;
  top: 0;
  width: 100%;
  p {
    color: ${({ $theme }) => $theme.textColor};
  }
`;

export default eventRegistrationTemplate;
